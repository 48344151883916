import * as React from 'react';
import { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/seo';
import { MenuContent } from '../components/menu/MenuContent';
import { IndexHero } from '../components/IndexHero';
import { useLayoutContext } from '../contexts/LayoutContext';
import { useMountEffect } from '../hooks/useMountEffect';
import { PageIndexQuery } from '../generated/graphql-types';
import { useLocaleContext } from '../contexts/LocaleContext';
import { getAllLocales } from '../lib/locale';

const indexTranslations = Object.entries(getAllLocales()).map(([locale, localeValue]) => ({
	fields: { locale },
	path: `/${localeValue.path}`,
}));

const PageIndex = ({ data }: PageProps<PageIndexQuery, { translations: any }>) => {
	const { setBreadcrumbs } = useLayoutContext();
	const body = data?.mdx?.body || '';
	const frontmatter = data?.mdx?.frontmatter;
	const { setPageTranslations } = useLocaleContext();

	useEffect(() => {
		setPageTranslations(indexTranslations);
	}, []);

	useMountEffect(() => {
		setBreadcrumbs([]);
	});

	return (
		<>
			<SEO title={frontmatter?.title} />
			<main>
				<IndexHero>{body}</IndexHero>
			</main>
			<MenuContent />
		</>
	);
};

export const pageQuery = graphql`
	query PageIndex($language: String!) {
		mdx(fields: { locale: { eq: $language }, basename: { eq: "avaleht" } }) {
			body
			frontmatter {
				title
				logo
				background
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default PageIndex;
