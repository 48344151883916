import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { userShortcodes } from '../lib/userShortcodes';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { GatsbyImage as Img } from 'gatsby-plugin-image';

import { screenSize } from '../styles/ScreenSize';
import { useMediaLayout } from 'use-media';

import { AllIndexHeroImagesQuery } from '../generated/graphql-types';
import { useLocaleContext } from '../contexts/LocaleContext';

const InnerWrapper = styled.div`
	box-sizing: border-box;
	height: var(--hero-height);
	padding: var(--hero-padding);
	width: calc(38vw + 2 * var(--hero-padding));
	max-width: 768px;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;

	${screenSize.sm} {
		font-size: 16px;
	}
`;

const CoreWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;

	a:hover {
		color: var(--button-grey);
	}
`;

const Image = styled(Img)`
	width: 100%;
	height: var(--hero-height);
`;

const Background = styled.div`
	width: 100%;
	height: var(--hero-height);
	background-color: #0072ce;
`;

const LogoWrapper = styled.div`
	img {
		min-width: 280px;
	}
`;

const ESFLogoWrapper = styled.div<{ isHidden: Boolean }>`
	transform: translateX(${(p) => (p.isHidden ? '100%' : 0)});
	position: fixed;
	top: var(--nav-bar-height);
	padding-top: 3%;
	right: 0;
	transition: transform ease-in-out 0.2s;

	svg {
		background-color: white;
	}

	${screenSize.sm} {
		transform: translateX(${(p) => (p.isHidden ? '-100%' : 0)});
		position: fixed;
		top: auto;
		bottom: 8px;
		left: 16px;
		z-index: 1;
		transition: transform ease-in-out 0.2s;
	}
`;

interface IndexHero {
	className?: string;
	style?: string;
	children: string;
}

const indexHeroQuery = graphql`
	query AllIndexHeroImages {
		desktopImage: file(name: { eq: "avalehe_foto" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH)
			}
		}
		logos: allFile(filter: { name: { glob: "Tallinn_2035_logo.*" } }) {
			nodes {
				publicURL
				base
			}
		}
		esfLogos: allFile(filter: { name: { glob: "esf.*.*" } }) {
			nodes {
				publicURL
				base
			}
		}
	}
`;

export const IndexHero = ({ className, children }: IndexHero) => {
	const isMobile = useMediaLayout(screenSize.sm);

	let height = 0;

	if (typeof window !== 'undefined') {
		height = window.innerHeight;
	}

	const [isHidden, setHidden] = useState<boolean>(false);

	useScrollPosition(
		({ currPos }) => {
			if (-currPos.y > 0.3 * height) {
				setHidden(true);
			} else {
				setHidden(false);
			}
		},
		[isHidden]
	);

	const { desktopImage, logos, esfLogos } = useStaticQuery<AllIndexHeroImagesQuery>(indexHeroQuery);

	const desktopFluid = desktopImage?.childImageSharp?.gatsbyImageData;

	const { locale } = useLocaleContext();

	const currentLocaleLogo = logos.nodes.find((logoNode) => logoNode.base.includes(`.${locale}.`)) || logos.nodes[0];

	const currentLocaleEsfLogoVertical =
		esfLogos.nodes.find((logoNode) => logoNode.base.includes(`.vertical.${locale}.`)) || esfLogos.nodes[0];

	const currentLocaleEsfLogoHorisontal =
		esfLogos.nodes.find((logoNode) => logoNode.base.includes(`.horisontal.${locale}.`)) || esfLogos.nodes[0];

	return (
		<>
			<Image image={desktopFluid} alt={''} loading={'eager'}/>
			<InnerWrapper>
				<CoreWrapper>
					<MDXProvider components={userShortcodes}>
						<LogoWrapper>
							{currentLocaleLogo?.publicURL && (
								<img src={currentLocaleLogo.publicURL} alt="Tallinn 2035 Arengustrateegia logo" />
							)}
						</LogoWrapper>
						{!isMobile && <MDXRenderer>{children}</MDXRenderer>}
					</MDXProvider>
				</CoreWrapper>
			</InnerWrapper>

			{!isMobile && (
				<ESFLogoWrapper isHidden={isHidden}>
					{currentLocaleEsfLogoVertical?.publicURL && (
						<img src={currentLocaleEsfLogoVertical.publicURL} alt="EU social fund Logo" />
					)}
				</ESFLogoWrapper>
			)}
			{isMobile && (
				<ESFLogoWrapper isHidden={isHidden}>
					{currentLocaleEsfLogoHorisontal?.publicURL && (
						<img src={currentLocaleEsfLogoHorisontal.publicURL} alt="EU social fund Logo" />
					)}
				</ESFLogoWrapper>
			)}
		</>
	);
};
